/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */
/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2)
    format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2)
    format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2)
    format("woff2");
}
/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2)
    format("woff2");
}
/* hebrew */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2)
    format("woff2");
}
/* math */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTVOmu1aB.woff2)
    format("woff2");
}
/* symbols */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTUGmu1aB.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2)
    format("woff2");
}
/* cyrillic-ext */

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnohkk72xU.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnojUk72xU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnojEk72xU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnogkk7.woff2)
    format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnohkk72xU.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnojUk72xU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnojEk72xU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFkD-vYSZviVYUb_rj3ij__anPXDTnogkk7.woff2)
    format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTjYgFE_.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTPYgFE_.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTLYgFE_.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgA.woff2)
    format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTjYgFE_.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTPYgFE_.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTLYgFE_.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgA.woff2)
    format("woff2");
}
/* cyrillic-ext */

@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IALT8kU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IQLT8kU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2LwLT.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IALT8kU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IQLT8kU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2LwLT.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IALT8kU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IQLT8kU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2LwLT.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IALT8kU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IQLT8kU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2LwLT.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IALT8kU.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2IQLT8kU.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Au-p_0qiz-afTf2LwLT.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afT3GLRrX.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTzGLRrX.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTLGLQ.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afT3GLRrX.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTzGLRrX.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTLGLQ.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afT3GLRrX.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTzGLRrX.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTLGLQ.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afT3GLRrX.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTzGLRrX.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTLGLQ.woff2)
    format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afT3GLRrX.woff2)
    format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTzGLRrX.woff2)
    format("woff2");
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/muli/v29/7Auwp_0qiz-afTLGLQ.woff2)
    format("woff2");
}

/* start header */

.navigation-bar {
  display: block;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  background-color: rgb(126, 150, 128);
  transition: all;
  min-height: 106.983px;

  transform: translateZ(0);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.33);
  z-index: 99;
  left: 0px;
  width: 100%;
  position: fixed;
  top: 0px;
}
/* 
.navigation-bar.fixed {
  transform: translateZ(0);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.33);
  z-index: 99;
  left: 0px;
  width: 100%;
  position: fixed;
  top: 0px; 
} */

.gridContainer {
  box-sizing: border-box;
  max-width: 1232px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.custom-logo {
  width: auto;
  max-height: 75px;
}

.basis-auto {
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.custom-logo-link {
  display: inline-block;
}
.col-xs {
  padding: 0 15px;
}
.nmain-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  width: 100%;
  gap: 27px;
}

.nmain-menu li {
  display: block;
  position: relative;
  color: #ffffff;
  transition: all 0.4s;
  padding: 0.4rem 0;
}
.nmain-menu li a {
  color: #fff;
  /* font-family: "Playfair Display", Georgia, serif; */
  font-family: "Playfair Display", Georgia, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.nmain-menu li a:hover {
  color: #d7be69;
}

.nmain-menu li .sub-menu {
  margin-left: 0px;
  display: block;
  box-sizing: border-box;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  position: absolute;
  padding-left: 0em;
  min-width: 200px;
  z-index: 10;
  transition: none;
  top: 100%;
  background-color: rgb(126, 150, 128);
}
.nmain-menu li:hover .sub-menu {
  opacity: 1;
  height: auto;
  overflow: visible;
}

.nmain-menu li .sub-menu li {
  border-top: 0;
  line-height: 120%;
  font-weight: 600;
  position: relative;
  padding: 0;
  font-size: 14px;
}
.nmain-menu li .sub-menu li:hover {
  background-color: #f8f8f8;
  color: rgb(215, 190, 105);
}
.nmain-menu li .sub-menu li::after {
  display: block;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.2);
  position: absolute;
  left: 0px;
  bottom: 0px;
  mix-blend-mode: exclusion;
}
.nmain-menu li .sub-menu li a {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0px;
  text-transform: none;
  padding: 0.75rem 1.5rem;
  display: block;
  color: rgb(215, 190, 105);
}
.submenudrop::after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  color: inherit;
  font-size: 0.8em;
  box-sizing: border-box;
  width: 20px;
  height: 100%;
  text-align: center;
  position: absolute;
  right: 1em;
  opacity: 0.9;
  vertical-align: middle;
  position: relative;
  right: 0px;
  margin-left: -0.2em;
  margin-top: -0.3em;
}
.nmain-menu li .submenudrop:hover::after {
  content: "\f0d8";
}

.mesmerize-menu-cart i {
  font-size: 16px;
}
.mesmerize-menu-cart a {
  padding-left: 6px;
  padding-right: 6px;
}
.hide {
  display: none !important;
}

/*  start footer */
.footer .footer-content {
  background-color: #2a363b;
  border-top-color: rgb(126, 150, 128);
  border-top-width: 1px;
  border-top-style: solid;
  position: relative;
  z-index: 0;
}

.footer {
  background-color: #ffffff;
  margin-bottom: -1px;
  color: #ffffff;
  position: static;
}
.footer a {
  color: #ffffff;
}
.textwidget a {
  text-decoration: underline;
}
.footer-7 h4 {
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15.408px;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: none;
}
.footer-7 h1.title,
.footer-7 h1,
.footer-7 h2,
.footer-7 h3,
.footer-7 h4,
.footer-7 h5,
.footer-7 .comment-author .fn,
.footer-7 h6 {
  text-rendering: optimizeLegibility;
  margin-bottom: 16px;
}
.small,
.comment-meta,
.comment-reply-link {
  font-size: 14px;
  line-height: 16px;
  font-family: "Playfair Display", Georgia, serif;
  font-weight: 300;
  font-style: normal;
}
.footer-7 .p,
.footer-7 p,
.footer-7 blockquote,
.footer-7 hr,
.footer-7 pre,
.footer-7 ol,
.footer-7 ul,
.footer-7 dl,
.footer-7 table,
.footer-7 fieldset,
.footer-7 figure,
.footer-7 address,
.footer-7 form {
  margin-bottom: 16px;
}
.footer .button:only-child,
.footer p:last-child,
.footer h1:last-child,
.footer h2:last-child,
.footer h3:last-child,
.footer h4:last-child,
.footer h5:last-child,
.footer .comment-author .fn:last-child,
.footer h6:last-child {
  margin-bottom: 0;
}

.footer p,
.footer a {
  word-wrap: break-word;
}
#menu-footer-menu-main li a {
  text-decoration: underline;
}
#menu-footer-menu-main li a:hover {
  color: #d7be69;
  text-decoration: underline !important;
} 
.textwidget a:hover{
  color: #d7be69;
  text-decoration: underline !important; 
}
.footer .widget > ul > li a {
	font-size: 16px;
	text-decoration: none;
}
.footer a:hover {
	color: #D7BE69;
}

.footer ul {
  list-style: none;
  margin: 0;
  line-height: 32px;
}
.footer-social-icons {
	max-width: 100%;
	margin: -5px;
	display: flex; 
}
.footer-social-icons a {
	display: inline-block;
	font-size: 22px;
	margin: 5px;
	max-height: 40px;
	opacity: 0.9;
	transition: all 0.3s linear;
}
.last-xs {
	order: 1; 
}
.footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6 {
	color: #d7be69 !important;
	word-wrap: break-word;
	text-transform: uppercase; 
}
.header-height.active {
	height: 102px;  
}
.mesmerize-menu-cart .rcs_cart_badge {
	position: absolute;
	top: 0;
	right: -5px;
	width: 16px;
	height: 16px;
	border-radius: 100%;
	text-align: center;
	font-size: 11px;
	line-height: 16px;
	font-weight: 600; 
}





/* Responsive Css */

@media (min-width:1280px) and (max-width: 1439px) {

  .nmain-menu { 
    gap: 24px;
  }
} 
@media (min-width:992px) and (max-width: 1279px) {
  .header-height.active {
    height: 134px;  
  } 
}
/* @media (max-width: 1440px) {
  .nmain-menu { 
    gap: 24px;
  }
} */
@media (min-width: 768px) {
  .gridContainer {
    width: 90%;
  }
  .basis-auto {
    margin-top: 2px;
  }
  .hamb-menu {
    display: none;
  }
  .hamb-menubtn.btn.btn-primary {
    display: none;
  }
  
  .footer-7 .widgettitle,
  .footer-7 h4 {
    text-transform: uppercase;
  }
  /*  start footer */
  .footer .footer-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .first-sm {
    order: -1;
  }
  .footer-7 h4 {
    font-size: 17.6px;
  }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .footer-7 .footer-social-icons {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column; 
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .nmain-menu {
    display: none;
  }

  .navigation-bar {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    min-height: 102.983px;
  }
  .hamb-menubtn.btn.btn-primary {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    transition: all 0.2s linear;
    background-color: rgba(0, 0, 0, 0.1);
    color: #000000;
    border: none;
    font-size: 16px;
    padding: inherit;
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  .mui-drawers .css-1ab2xsx, .mui-drawers .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 300px; 
    background-color: #222b34;
    transition: transform 0.3s linear !important;
    -webkit-transition: transform 0.3s linear !important;
    -moz-transition: transform 0.3s linear !important;
    -o-transition: transform 0.3s linear !important;
    padding: 0px;
    overflow: auto;
    z-index: 99999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; 
    color: #fff;
    box-shadow: none;
  }
  .offcanvas-top {
    padding-top: 28px;
    padding-bottom: 28px;
    text-align: center; 
  }
  .offcanvas-top a {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
  }
  .custom-logo-link {
    display: block;
    margin: auto;
    text-align: center;
  }
  .offcanvas_menu {
    margin-left: 0px;
    list-style: none;
    margin-bottom: 16px;
  }
  .offcanvas_menu li {
    color: #ffffff;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-size: 0.8rem;
    text-align: left;
    transition: all 0.1s linear;
  }
  .offcanvas_menu li a {
    color: #fff;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12.25px;
    line-height: 100%;
    letter-spacing: 0px;
    text-transform: none;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 10.25px 0 10.25px 35px;
    cursor: pointer;
  }
  .mesmerize-menu-cart-secondary i {
    font-size: 14px;
    margin-top: -0.05em;
    margin-left: -0.1em;
    margin-right: 0.5rem;
    display: inline-block;
  }
  .mnaccordion .MuiAccordionDetails-root.css-u7qq7e, .mnaccordion .css-15v22id-MuiAccordionDetails-root {
    padding: 0; 
  }
  .sub-menu {
    background: #686b77;
  }
  .offcanvas_menu .sub-menu li a {
    padding: 10.25px 0 10.25px 52.5px;
  }

  #panel1-header {
    color: #fff;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12.25px;
    line-height: 100%;
    letter-spacing: 0px;
    text-transform: none;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 10.7px 0 10.7px 35px;
    min-height: auto;
  }
  .mnaccordion .css-17o5nyn a 
  { 
    color: #fff; 
  }
  .mnaccordion .css-17o5nyn, .mnaccordion .css-o4b71y-MuiAccordionSummary-content {
    display: inline-block; 
    margin: 0;
    padding: 0;
  }
  .mnaccordion .css-1fx8m19 , .mnaccordion .css-yw020d-MuiAccordionSummary-expandIconWrapper {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;
    color: #fff;
    transform: rotate(-90deg);
    font-weight: 600;
  }
  .mnaccordion .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .mui-drawers .MuiPaper-rounded ,.mnaccordion.css-ree4sk-MuiPaper-root-MuiAccordion-root {
    background-color: transparent;
    color: #fff; 
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    border-radius: 0 !important; 
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  }
  .mnaccordion
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    background: #fff;
    min-height: auto;
  }
  .mnaccordion
    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content
    a {
    color: #fff;
    display: inline-block;
  }
  .mnaccordion .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content
    a {
    color: #2395f6;
  } 
  .mnaccordion
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    border-left: 3px solid #2395f6; 
  }  
  .css-1fx8m19.Mui-expanded
  {
    color: #2395f6; 
  }
  
   .mnaccordion .MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    color: #2395f6;
  }  
  .css-17o5nyn.Mui-expanded { 
    margin: 0;
  }
  .mnaccordion.css-jx2lfu #panel1-header
  {
    background-color: #fff; 
    border-left: 3px solid #2395f6;
  } 
  .Mui-expanded #panel1-header a { 
    color: #2395f6;
  }
  .footer-social-icons
  {
    margin: 0; 
  }
  .css-17o5nyn.Mui-expanded {
    margin: 0;
  }
  .footer .row:not(.no-spacing) > div:not(.no-gutter-col) { 
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .center-xs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  } 
  .footer{
    text-align: center; 
  }
  .small, .comment-meta, .comment-reply-link {
    font-size: 12.5px;
  }
  .footer-7 h4 {
    font-size: 13px;
  }
  .small, .comment-meta, .comment-reply-link {
    line-height: 14px; 
  }
  .footer .widget > ul > li a {
    line-height: 14px;
  }
  .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .mesmerize-menu-cart-secondary { 
    padding: 6.7px 0 6.7px 35px;
  }
}

/* Responsive Css */
